import { Box, IconButton, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Icon } from './icon'
import { theme } from 'src/theme/ThemeProvider'
import ReactMarkdown from 'react-markdown'
import { Strapi__Component_Links_Modal_Link } from 'gatsby-graphql'

export interface ITextModalProps {
  modalLink: Strapi__Component_Links_Modal_Link
}
const buttonHeight = 47
const NewTextModal = (props: any) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    props.handleClose()
    setOpen(false)
  }

  return (
    <span>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            outline: 'none',
            width: "100%",
          }}
        >
          <Box
            sx={{
              outline: 'none',
              width: "100%",
              maxWidth: { lg: 800, md: 800, xs: '90vw' },
              marginTop: '8vh',
              marginX: 'auto',
              position: 'relative',
              alignContent: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                outline: 'none',
                position: 'absolute',
                top: 20,
                right: 20,
                zIndex: 2,
                width: buttonHeight,
                height: buttonHeight,
                padding: '13px',
                background: theme.palette.background.default,
                '&:hover': {
                  background: theme.palette.background.default,
                },
              }}
            >
              <Icon icon="close" />
            </IconButton>
            <Box
              sx={{
                outline: 'none',
                padding: 0,
                background: theme.palette.background.paper,
                color: theme.palette.text.secondary,
                borderRadius: '5px',
                overflow: 'none',
                width: "100%"
              }}
            >
              <Box
                sx={{
                  outline: 'none',
                  height: {xs: '70vh', md:'60vh', lg: '60vh'}
                }}
              >
                <Box
                  sx={{
                    '& ul ::marker': { color: theme.palette.success.main },
                    '& ul': {
                      paddingLeft: '20px',
                    },
                    '& ol': {
                      paddingLeft: '20px',
                    },
                    fontSize: '16px',
                    height: "100%",
                    marginX: '40px',
                    paddingX: { md: '18px', xs: '0px' },
                    marginY: '69px',
                    borderRadius: '5px',
                    overflowY: 'scroll',
                    scrollbarColor: `${theme.palette.success.main} transparent`, //firefox
                    scrollbarWidth: 'thin', //firefox
                    '&::-webkit-scrollbar': {
                      display: 'block',
                      background: theme.palette.grey,
                      width: '10px',
                      borderRadius: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: theme.palette.success.main,
                      borderRadius: '5px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: 'calc(100% - 10%)',
                      margin: '18px',
                    }}
                  >
                    {props?.content?.title && (
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '30px',
                          fontWeight: 'bold',
                          marginBottom: '60px',
                          color: '#383C46',
                        }}
                      >
                        {props?.content?.title}
                      </Typography>
                    )}
                    {/* <Typography sx={{ width: '100%', textAlign: 'left', fontSize: '16px', marginBottom: '32px' }}>
                      Ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                      totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                      dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                      sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
                      quisquam est, qui dolorem ipsum quia dolor sit amet.
                    </Typography> */}
                    {props?.content?.content && <ReactMarkdown children={props?.content?.content} />}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </span>
  )
}
export default NewTextModal
